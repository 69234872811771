import Vue from "vue";
// import VueRouter, { RouteConfig } from "vue-router";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { store } from "../store/index";

// TODO lets breakdown routes into modules this single file is getting a bit long

// Vue.use(VueRouter);

// const routes: Array<RouteConfig> = [
const routes = [
  {
    path: "/password/reset/:token",
    name: "ResetPassword",
    component: () => import( "../components/auth/ResetPassword.vue"),
  },
  {
    path: "/password/reset",
    name: "Login",
    component: () =>
      import("../components/auth/Login.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () =>
      import(
       "../components/auth/Registration.vue"
      ),
  },
  {
    path: "/",
    name: "Dashboard",
    meta: { requiresAuth: true },
    component: () =>
      import("../views/Dashboard.vue"),
    children: [
      {
        path: "company",
        name: "company",
        component: () =>
          import(
            "../components/companies/CompanyContainer.vue"
          ),
        children: [
          {
            path: "signature",
            name: "signature",
            component: () =>
              import(
                 "../components/companies/SignatureContent.vue"
              ),
          },
          {
            path: "premium",
            name: "premium",
            component: () =>
              import(
                "../components/companies/PremiumContent.vue"
              ),
          },
          {
            path: "annual-report",
            name: "annualReport",
            component: () =>
              import(
                "../components/companies/AnnualReport.vue"
              ),
          },
        ],
      },
      {
        path: "product",
        component: () =>
          import(
            "../components/products/ProductContainer.vue"
          ),
        name: "product",
        children: [
          {
            path: "",
            name: "list",
            props: true,
            // meta: { transition: 'slide-left' },
            component: () =>
              import(
                "../components/products/ProductList.vue"
              ),
          },
          {
            path: ":productId/details",
            name: "details",
            props: true,
            // meta: { transition: 'slide-right' },
            component: () =>
              import(
                "../components/products/ProductDetails.vue"
              ),
            children: [
              {
                path: "summary",
                name: "summary",
                component: () =>
                  import(
                    "../components/products/ProductSummary.vue"
                  ),
              },
              {
                path: "content",
                name: "content",
                component: () =>
                  import(
                    "../components/products/ProductContent.vue"
                  ),
              },
              {
                path: "cta",
                name: "cta",
                component: () =>
                  import(
                    "../components/products/ProductCTA.vue"
                  ),
              },
              {
                path: "gs1-setup",
                name: "gs1-setup",
                component: () =>
                  import(
                    "../components/products/Gs1Components/Gs1Setup.vue"
                  ),
              },
              {
                path: "real-code",
                name: "real-code",
                component: () =>
                  import(
                    "../components/products/RealCodes.vue"
                  ),
              },
              {
                path: "product-settings",
                name: "product-settings",
                component: () =>
                  import(
                     "../components/products/ProductSettings.vue"
                  ),
              },
            ],
          },
        ],
      },
      {
        path: "ctas",
        name: "ctas",
        component: () =>
          import(
            "../components/companies/CTAList.vue"
          ),
      },
      {
        path: "analytics",
        name: "analytics",
        component: () =>
          import(
             "../components/companies/Analytics.vue"
          ),
      },
      {
        path: "assets",
        name: "assets",
        component: () =>
          import(
            "../components/companies/AssetList.vue"
          ),
      },
    ],
  },
  // {
  //   path: "billing-update",
  //   name: "billingPlan",
  //   component: () =>
  //     import(
  //       /* webpackChunkname: "billing" */ "../components/accounting/BillingUpdatePlan.vue"
  //     ),
  // },
  {
    path: "/profile",
    name: "profile",
    component: () =>
      import(
        "../components/auth/UserProfile.vue"
      ),
  },
  { path: "/sections-lists", redirect: { name: "associations" } },

  {
    path: "/admin",
    name: "adminDashboard",
    component: () =>
      import(
        "../views/AdminDashboard.vue"
      ),

    children: [
      {
        path: "sections-and-lists",
        name: "sections",
        component: () =>
          import(
            "../components/administration/SectionsAndListsContainer.vue"
          ),
        children: [
          {
            path: "",
            name: "sections-lists",
            component: () =>
              import(
                "../components/administration/SectionsLists.vue"
              ),
            children: [
              {
                path: "associations",
                name: "associations",
                component: () =>
                  import(
                    "../components/administration/Associations.vue"
                  ),
              },
              {
                path: "content-sections",
                name: "content-sections",
                component: () =>
                  import(
                    "../components/administration/ContentSections.vue"
                  ),
              },
              {
                path: "tags",
                name: "tags",
                component: () =>
                  import(
                    "../components/administration/Tags.vue"
                  ),
              },
            ],
          },
        ],
      },

      // {
      //   path: "sections-and-lists",
      //   name: "sections",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "sectionsandlistcontainer" */ "../components/administration/SectionsAndListsContainer.vue"
      //     ),
      //   children: [
      //     {
      //       path: "",
      //       name: "sections-lists",
      //       component: () =>
      //         import(
      //           /* webpackChunkName: "sectionlists" */ "../components/administration/SectionsLists.vue"
      //         ),
      //       children: [
      //         {
      //           path: "associations",
      //           name: "associations",
      //           component: () =>
      //             import(
      //               /* webpackChunkName: "associations" */ "../components/administration/Associations.vue"
      //             ),
      //         },
      //         {
      //           path: "content-sections",
      //           name: "content-sections",
      //           component: () =>
      //             import(
      //               /* webpackChunkName: "contentsections" */ "../components/administration/ContentSections.vue"
      //             ),
      //         },
      //       ],
      //     },
      //   ],
      // },
      {
        path: "companies",
        component: () =>
          import(
            "../components/administration/AdminContainer.vue"
          ),
        name: "companies",
        children: [
          {
            path: "",
            name: "companyList",
            component: () =>
              import(
                "../components/administration/CompanyList.vue"
              ),
          },
          {
            // path: ":productId/details",
            // path: "details",
            path: ":companyId",
            name: "companyAccount",
            component: () =>
              import(
                "../components/administration/CompanyAccount.vue"
              ),
            children: [
              {
                path: "details",
                name: "companyAdminDetails",
                component: () =>
                  import(
                    "../components/administration/CompanyAdminDetails.vue"
                  ),
              },
              {
                path: "users",
                name: "companyAdminUsers",
                component: () =>
                  import(
                    "../components/administration/CompanyAdminUsers.vue"
                  ),
              },
              {
                path: "tags",
                name: "companyAdminTags",
                component: () =>
                  import(
                    "../components/administration/CompanyImpactTags.vue"
                  ),
              },
              {
                path: "tokens",
                name: "companyAdminTokens",
                component: () =>
                  import(
                    "../components/administration/GenerateUserToken.vue"
                  ),
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "/forgot-password",
    name: "forgotPassword",
    component: () =>
      import(
         "../components/auth/ForgotPassword.vue"
      ),
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import( "../components/auth/Login.vue"),
    meta: {
      guestOnly: true,
    },
  },
  {
    path: "/404",
    name: "404",
    component: () =>
      import("../views/NotFound.vue"),
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/404",
  },
];

// const router = new VueRouter({
//   mode: "history",
//   linkExactActiveClass: "exact-active",
//   base: import.meta.env.BASE_URL,
//   routes,
// });

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  // history: createWebHistory(),
  linkExactActiveClass: "exact-active",
  // base: import.meta.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters.isLoggedIn;
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    try {
      if (!isAuthenticated) {
        next({
          path: "/login",
          query: { redirect: to.fullPath },
        });
      } else {
        next();
      }
    } catch (error) {
      return false;
    }
  } else {
    next(); // make sure to always call next()!
  }
});

export default router;
